import React from 'react';
import industryImage from '../../assets/industry.jpg';

const IndustryCard = ({ title, description, link }) => (
  <a href={link} aria-label={title} style={{ cursor: 'pointer' }} className="group col-span-1 h-48 rounded-lg overflow-hidden relative bg-gray-400" >
    <img src={industryImage} alt={title} className="w-full h-full object-cover" />
    <div className="absolute inset-0 bg-gray-800 bg-opacity-60 p-8 text-left hover:bg-opacity-40 transition-all duration-300">
      <h2 className="mb-4 max-w-xl text-2xl font-extrabold leading-tight tracking-tight text-white group-hover:underline">{title}</h2>
      <p className="mb-4 font-light text-white">{description}</p>
      {/* <button type="button" className="inline-flex items-center py-2.5 text-center font-medium text-white focus:outline-none focus:ring-4 focus:ring-gray-700 group-hover:font-semibold group-hover:underline">
        Show more <span aria-hidden="true"> →</span>
      </button> */}
    </div>
  </a >
);

const IndustriesServedSection = () => {
  const industries = [
    {
      title: "SaaS Products",
      description: "Robust and secure SaaS solutions tailored for construction management.",
      // link: "/industry/healthcare-and-lifesciences"
    },
    {
      title: "Retail & eCommerce",
      description: "Scalable solutions for B2B and B2C consumer brands and marketplaces.",
      // link: "/industry/retail-and-ecommerce"
    },
    {
      title: "Entertainment",
      description: "Innovative and dynamic applications designed for the fantasy sports industry",
      // link: "/industry/supply-chain-and-logistics"
    }
  ];

  return (
    <section className="bg-gray-50">
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-16 2xl:max-w-screen-2xl">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-extrabold leading-tight text-gray-900 dark:text-black sm:text-4xl">Industries We’ve Served</h2>
          <p className="text-light mt-4 text-gray-500 dark:text-gray-400 sm:text-xl">We deliver software outsourcing solutions to a broad range of industries around the globe, offering tailored expertise and innovative approaches to meet unique needs. Our global reach ensures that we can effectively support and drive success across various sectors.</p>
        </div>
        <div className="mt-16 grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3">
          {industries.map((industry, index) => (
            <IndustryCard key={index} {...industry} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default IndustriesServedSection;