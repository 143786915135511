import React from 'react';

const Testimonial = ({ title, content, author, role, imageSrc }) => (
  <figure className="rounded bg-gray-50 p-6 light:bg-gray-800" data-aos="fade-up">
    <blockquote className="text-sm text-gray-500 dark:text-gray-400">
      <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{title}</h3>
      <p className="my-4">{content}</p>
    </blockquote>
    <figcaption className="flex items-center space-x-3">
      <img className="h-9 w-9 rounded-full" src={imageSrc} alt="profile picture" />
      <div className="space-y-0.5 font-medium dark:text-white">
        <div>{author}</div>
        <div className="text-sm font-light text-gray-500 dark:text-gray-400">{role}</div>
      </div>
    </figcaption>
  </figure>
);

const TestimonialsSection = () => {
  const testimonials = [
    {
      title: "Solid Staff-Augmentation modal",
      content: "\"Parv was one of the best contract employees I have ever worked with. He was always committed to his work and took complete ownership of the tasks assigned to him. He was strong at his iOS skillset and was able to deliver the tasks within the expected timeline. He was a great team player and the entire team including his project manager shares good positive feedback about him.\"",
      author: "Sanath Kumar",
      role: "Engineering Manager at YML",
      imageSrc: "../images/people/sanath.png"
    },
    {
      title: "Excellent QA Leadership",
      content: "\"Neha displayed exceptional composure under pressure, navigating complex challenges with grace and ensuring that our projects stayed on track. Her ability to handle high-pressure situations not only inspired confidence but also set a great example for the rest of the team. Moreover, Neha demonstrated remarkable team spirit by consistently fostering collaboration and open communication within the team. Her approachable nature and willingness to help colleagues created a cohesive and productive work environment.\"",
      author: "Vinay S.",
      role: "Engineering Manager at Y Media Labs",
      imageSrc: "../images/people/avatar.png"
    },
    {
      title: "Excellent Delivery and Quality",
      content: "\"I want to take a moment to thank you for all the amazing work you have been doing over the past few years. I appreciate you leading all the backend development efforts and carefully architecting and moulding the applications with all your expertise. I would like to recognize your efforts in guiding your teammates and coordinating with cross functional teams in terms of development (Mobile App Developer), testing (QA Team) and fixing production issues (Operations team).\"",
      author: "Tarun Aleti",
      role: "Co-founder & CTO at Partrunner",
      imageSrc: "../images/people/tarun.png"
    },
    {
      title: "Quick action on feedback",
      content: "\"Satisfied with his performance. Initially, some feedback was given regarding proactiveness and attending late-night calls. Both have been addressed, and currently, all is going smoothly.\"",
      author: "Anand Bhagwat",
      role: "QA Engineering Manager at Sibros",
      imageSrc: "../images/people/anand.png"
    },
    {
      title: "Smooth communication",
      content: "\"Communication with Deepika & Development team is going as per expected - no concerns here Overall happy with Crownstack!\"",
      author: "James Riggen",
      role: "Co-founder & President of LX Medical",
      imageSrc: "../images/people/james.png"
    },
    {
      title: "Exceeding Expectations",
      content: "\"Overall everything is quite positive - The engineering team has met the expectations well, especially Anita & Riya. New members are trying equally hard and sound mature and have skills at par to run the operations (kotesh & Anuradha)\"",
      author: "Steven Paolucci",
      role: "CTO at Nucleus Healthcare",
      imageSrc: "../images/people/steven.png"
    },
    {
      title: "Great UI/UX Designers",
      content: "\"Design - So far the experience with design team has been very positive - looking forward to continuing work with them. \"",
      author: "Lucas Wittrup",
      role: "Co-Founder at Squire",
      imageSrc: "../images/people/lucas.png"
    },
    {
      title: "Speedy Delivery",
      content: "\"The pace of delivery is good. We are more confident and comfortable with the team now. Overall, all are happy and can see the difference in delivery, the pace of work, quality and PM by the time he returns from his vacation.\"",
      author: "Dmitry Petrov",
      role: "Head of Innovation at Seers",
      imageSrc: "../images/people/dmitry.png"
    }
  ];

  return (
    <section className="bg-white light:bg-gray-900">
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16 2xl:max-w-screen-2xl">
        <div className="mx-auto max-w-screen-md text-center">
          <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            Client Testimonials
          </h2>
          <p className="mb-8 font-light text-gray-600 dark:text-gray-400 sm:text-xl md:text-lg lg:mb-16">
            Check what our customers are saying about us and our employees in NPS survey which are conducted frequently
          </p>
        </div>
        <div className="grid gap-8 lg:grid-cols-3">
          {[0, 1, 2].map((columnIndex) => (
            <div key={columnIndex} className="space-y-6">
              {testimonials
                .filter((_, index) => index % 3 === columnIndex)
                .map((testimonial, index) => (
                  <Testimonial key={index} {...testimonial} />
                ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;