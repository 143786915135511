import React from 'react';
import image1 from '../../assets/about1.jpg'
import FoundersCTA from '../../components/FoundersCTA';

function About() {
  return (
    <div className="relative bg-gray-50">
      <div className="mx-auto w-[80%] px-4 lg:px-8">
        <h2 className="mt-10 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">Who are we?</h2>
        <p className="mt-6 text-gray-600 dark:text-gray-400 text-center">
          We specialize in delivering innovative and creative products and services designed to offer comprehensive information solutions. Our diverse range of offerings includes web design and development, custom applications, ERP and CRM systems, e-commerce platforms, B2B and B2C applications, managed hosting services, and internet portal management, among others.
        </p>
      </div>


      <div className="mx-auto grid max-w-7xl lg:grid-cols-2">
        <div className="relative lg:col-span-1 mt-20">
          <img
            className="h-100 w-full object-cover"
            src={image1}
            alt="Team working together"
          />
        </div>
        <div className="px-6 pb-24 pt-16 sm:pb-16 sm:pt-20 lg:col-span-1 lg:px-8 lg:pt-16">
          <div className="mx-auto max-w-2xl lg:max-w-lg">
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Mission</h2>
            <p className="mt-6 text-gray-600 dark:text-gray-400">
              Our mission is to deliver best-in-class services, maximizing the growth potential for both our clients and employees.
              <br /><br />
              <span className="mt-6 text-gray-600 dark:text-gray-400">
                We not only deliver on our promises but consistently go above and beyond. For us, commitments and deadlines are paramount, and we dedicate ourselves fully to meeting and exceeding them.
              </span>
              <br /><br />
              <span className="mt-6 text-gray-600 dark:text-gray-400">
                We create exceptional value for our customers and employees, with value generation at the heart of everything we do. Our focus is on continually maximizing this value to drive success.
              </span>
            </p>
          </div>
          <div className="mx-auto max-w-2xl lg:max-w-lg">
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Philosophy</h2>
            <p className="mt-6 text-gray-600 dark:text-gray-400">
              Highest Quality Product: Ziqonfox is committed to delivering products that meet the highest standards of quality, ensuring excellence in every detail.
              <br /><br />
              Total Client Satisfaction: We prioritize client satisfaction by tailoring our solutions to their unique needs and providing exceptional customer service throughout the process.
              <br /><br />
              Timely Delivery: We understand the importance of meeting deadlines and consistently ensure on-time delivery of solutions without compromising on quality.
              <br /><br />
              Best Quality/Price Ratio: Ziqonfox offers industry-leading value by combining high-quality solutions with competitive pricing, delivering the best return on investment for our clients.
            </p>
          </div>
        </div>
      </div>
      <FoundersCTA />
    </div>
  );
}

export default About;
