import React, { useState, useEffect } from 'react';

const TyperComponent = () => {
  const [text, setText] = useState('');
  const [showCursor, setShowCursor] = useState(true);

  const phrases = [
    "Solution Engineering",
    "User-Centered Design",
    "Generative AI",
    "Quality Assurance",
    "Data Science",
    "Clean Code Practice"
  ];

  const typeText = async (newText) => {
    const typeSpeed = 40;
    const pauseDuration = 2300;

    // Erase current text
    for (let i = text.length; i > 0; i--) {
      setText(text.slice(0, i - 1));
      await new Promise(resolve => setTimeout(resolve, typeSpeed));
    }

    // Type new text
    for (let i = 0; i < newText.length; i++) {
      setText(newText.slice(0, i + 1));
      await new Promise(resolve => setTimeout(resolve, typeSpeed));
    }

    // Pause with cursor
    await new Promise(resolve => setTimeout(resolve, pauseDuration));

    // Remove cursor
    setShowCursor(false);
    await new Promise(resolve => setTimeout(resolve, 500)); // Pause briefly without cursor
    setShowCursor(true);
  };

  useEffect(() => {
    let isMounted = true;

    const animateText = async () => {
      while (isMounted) {
        for (const phrase of phrases) {
          if (!isMounted) break;
          await typeText(phrase);
        }
      }
    };

    setTimeout(() => {
      animateText();
    }, 1000);

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <span className="m-0 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 bg-clip-text p-0 text-transparent">
      {text}
      {showCursor && <span className="font-extrabold text-sky-400">|</span>}
    </span>
  );
};

export default TyperComponent;