import React from 'react';
import TyperComponent from '../TyperComponent';
import Mockup from '../../assets/banner.jpg';


function Hero() {
  return (
    <section className="bg-white">
      <div className="mx-auto grid max-w-screen-xl px-4 py-8 lg:grid-cols-12 lg:gap-8 lg:py-16 xl:gap-0 2xl:max-w-screen-2xl">
        <div className="mr-auto place-self-center lg:col-span-7">
          <h1 className="mb-4 max-w-2xl text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-black md:text-5xl lg:text-5xl xl:text-6xl">
            Crafting sustainable, future-ready solutions with
          </h1>
          <h1 className="mb-4 max-w-2xl text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-black md:text-5xl lg:text-5xl xl:text-6xl">
            <TyperComponent />
          </h1>
          <p className="mb-6 max-w-2xl font-light text-gray-500 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-xl">
            Whether launching from the ground up or enhancing an existing product suite, we bring the expertise and practical approach needed to drive real business results.
          </p>
          <a href="/contact-us" className="inline-flex items-center justify-center rounded-md bg-sky-600 px-5 py-3 text-center text-base font-medium text-white hover:bg-sky-800 focus:ring-4 focus:ring-sky-300 dark:focus:ring-sky-900">
            Contact Us
            <svg className="ml-2 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
            </svg>
          </a>
        </div>
        <div className="hidden lg:col-span-5 lg:mt-0 lg:flex">
          <img src={Mockup} alt="Mockups" />
        </div>
      </div>
    </section>
  );
}

export default Hero;