import React from 'react';

const ServiceCard = ({ icon, title, description, link }) => (
  <div className="group rounded bg-white p-4 shadow hover:shadow-md">
    <div className="mb-4 flex h-8 w-8 items-center justify-center rounded bg-sky-100 transition delay-100 duration-200 ease-in-out group-hover:scale-110 lg:h-10 lg:w-10">
      {icon}
    </div>
    <a aria-label={title} href={link}>
      <h3 className="mb-2 text-xl font-semibold group-hover:underline dark:text-gray-900">{title}</h3>
    </a>
    <p className="pb-2 font-light text-gray-500 dark:text-gray-400">{description}</p>
  </div>
);

const ServicesSection = () => {
  const services = [
    {
      icon: (
        <svg class="w-6 h-6 text-cyan-500 dark:text-cyan" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m8 8-4 4 4 4m8 0 4-4-4-4m-2-3-4 14" />
        </svg>

      ),
      title: "Solution Engineering",
      description: "Address your unique business challenges with impactful mobile and web software solutions.",
      link: "/digital-product-engineering"
    },
    {
      icon: (
        <svg class="w-6 h-6 text-cyan-500 dark:text-cyan" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 5 9 4V3m5 2 1-1V3m-3 6v11m0-11a5 5 0 0 1 5 5m-5-5a5 5 0 0 0-5 5m5-5a4.959 4.959 0 0 1 2.973 1H15V8a3 3 0 0 0-6 0v2h.027A4.959 4.959 0 0 1 12 9Zm-5 5H5m2 0v2a5 5 0 0 0 10 0v-2m2.025 0H17m-9.975 4H6a1 1 0 0 0-1 1v2m12-3h1.025a1 1 0 0 1 1 1v2M16 11h1a1 1 0 0 0 1-1V8m-9.975 3H7a1 1 0 0 1-1-1V8" />
        </svg>

      ),
      title: "Quality Assurance",
      description: "Develop robust solutions that are both bug-free and efficient under demanding conditions.",
      link: "/quality-engineering"
    },
    {
      icon: (
        <svg class="w-6 h-6 text-cyan-500 dark:text-cyan" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h3a3 3 0 0 0 0-6h-.025a5.56 5.56 0 0 0 .025-.5A5.5 5.5 0 0 0 7.207 9.021C7.137 9.017 7.071 9 7 9a4 4 0 1 0 0 8h2.167M12 19v-9m0 0-2 2m2-2 2 2" />
        </svg>

      ),
      title: "Cloud & DevOps Solutions",
      description: "Achieve business agility and flexibility with our DevOps Services, leveraging the cloud’s scalability and data security.",
      link: "/digital-product-engineering"
    },
    {
      icon: (
        <svg class="w-6 h-6 text-cyan-500 dark:text-cyan" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 6c0 1.657-3.134 3-7 3S5 7.657 5 6m14 0c0-1.657-3.134-3-7-3S5 4.343 5 6m14 0v6M5 6v6m0 0c0 1.657 3.134 3 7 3s7-1.343 7-3M5 12v6c0 1.657 3.134 3 7 3s7-1.343 7-3v-6" />
        </svg>

      ),


      title: "Data Solutions",
      description: "Unlock a competitive advantage with data-driven insights and advanced analytics.",
      link: "/digital-product-engineering"

    },
    {
      icon: (
        <svg class="w-6 h-6 text-cyan-500 dark:text-cyan" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M16 19h4a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-2m-2.236-4a3 3 0 1 0 0-4M3 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
        </svg>

      ),


      title: "Workforce Augmentation",
      description: "Expand your engineering teams as needed with our expert developers and consultants, and gain a competitive edge through the strategic use of data-driven insights and advanced analytics.",
      link: "/digital-product-engineering"

    },
    {
      icon: (
        <svg class="w-6 h-6 text-cyan-500 dark:text-cyan" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.079 6.839a3 3 0 0 0-4.255.1M13 20h1.083A3.916 3.916 0 0 0 18 16.083V9A6 6 0 1 0 6 9v7m7 4v-1a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1Zm-7-4v-6H5a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h1Zm12-6h1a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-1v-6Z" />
        </svg>


      ),


      title: "User-Centered Design",
      description: "Enhance user engagement and create exceptional experiences with our design solutions.",
      link: "/digital-product-engineering"

    },
    {
      icon: (
        <svg class="w-6 h-6 text-cyan-500 dark:text-cyan" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 15h12M6 6h12m-6 12h.01M7 21h10a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1Z" />
        </svg>

      ),


      title: "Modern Application",
      description: "Prepare your application for the future by enhancing its tech stack, code, and cloud infrastructure."

    },
    {
      icon: (
        <svg class="w-6 h-6 text-cyan-500 dark:text-cyan" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18.5A2.493 2.493 0 0 1 7.51 20H7.5a2.468 2.468 0 0 1-2.4-3.154 2.98 2.98 0 0 1-.85-5.274 2.468 2.468 0 0 1 .92-3.182 2.477 2.477 0 0 1 1.876-3.344 2.5 2.5 0 0 1 3.41-1.856A2.5 2.5 0 0 1 12 5.5m0 13v-13m0 13a2.493 2.493 0 0 0 4.49 1.5h.01a2.468 2.468 0 0 0 2.403-3.154 2.98 2.98 0 0 0 .847-5.274 2.468 2.468 0 0 0-.921-3.182 2.477 2.477 0 0 0-1.875-3.344A2.5 2.5 0 0 0 14.5 3 2.5 2.5 0 0 0 12 5.5m-8 5a2.5 2.5 0 0 1 3.48-2.3m-.28 8.551a3 3 0 0 1-2.953-5.185M20 10.5a2.5 2.5 0 0 0-3.481-2.3m.28 8.551a3 3 0 0 0 2.954-5.185" />
        </svg>

      ),


      title: "Generative AI",
      description: "Unlock limitless possibilities with our Generative AI-powered software solutions, and future-proof your application by enhancing its tech stack, code, and cloud infrastructure."

    },
  ];

  return (
    <section className="bg-gray-50" id="services">
      <div className="mx-auto max-w-screen-xl px-8 py-8 sm:py-16 2xl:max-w-screen-2xl">
        <div className="mx-auto mb-8 max-w-screen-md text-center lg:mb-16">
          <h2 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 dark:text-black :text-4xl">Our Digital Transformation Services</h2>
          <p className="font-light text-gray-700 dark:text-gray-600 sm:text-xl">We craft outstanding software with clean code, agile methods, and exceptional user experiences.</p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 md:gap-4 md:space-y-0 lg:grid-cols-3 xl:grid-cols-4 xl:gap-4">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;