import React from 'react';
// import WorkShowcase from '../../components/WorkShowCase';
import TestimonialSection from '../../components/Testimonials';
import FoundersCTA from '../../components/FoundersCTA';

function Work() {
  return (
    <div className="Work">
      {/* <WorkShowcase /> */}
      <TestimonialSection />
      <FoundersCTA />
    </div>
  );
}

export default Work;