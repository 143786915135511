import React from 'react';

const EngagementModelCard = ({ icon, title, description, features, linkText, linkHref, color }) => (
  <div className="flex flex-col">
    <div className={`mb-4 p-2 w-16 h-16 flex items-center justify-center rounded-lg ${color.bg}`}>
      {icon}
    </div>
    <h3 className="mb-2 text-xl font-bold text-gray-900">{title}</h3>
    <p className="mt-1 text-gray-600 flex-grow">{description}</p>
    <ul className="mt-4 space-y-2">
      {features.map((feature, index) => (
        <li key={index} className="flex items-start">
          <svg className={`h-5 w-5 ${color.text} mr-2 mt-0.5`} fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
          </svg>
          <span className="text-gray-600">{feature}</span>
        </li>
      ))}
    </ul>
    {/* <a href={linkHref} className={`mt-4 inline-flex items-center ${color.text} hover:underline`}>
      {linkText}
      <svg className="ml-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
      </svg>
    </a> */}
  </div>
);

const EngagementModelsSection = () => {
  const engagementModels = [
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-blue-500">
          <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M4.5 17H4a1 1 0 0 1-1-1 3 3 0 0 1 3-3h1m0-3.05A2.5 2.5 0 1 1 9 5.5M19.5 17h.5a1 1 0 0 0 1-1 3 3 0 0 0-3-3h-1m0-3.05a2.5 2.5 0 1 0-2-4.45m.5 13.5h-7a1 1 0 0 1-1-1 3 3 0 0 1 3-3h3a3 3 0 0 1 3 3 1 1 0 0 1-1 1Zm-1-9.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
        </svg>
      ),
      title: "Solution Engineering Teams",
      description: "It is a fully autonomous, expert team, encompassing roles such as project managers, software engineers, and QA specialists. This team is adept at delivering technology solutions quickly and effectively, ensuring top-notch quality and efficiency through specialized expertise and coordinated execution.",
      features: [
        "Diverse Expertise",
        "Agile & DevOps Practices",
        "Flexible Billing",
        "Scalable Resources",
        "Ideal for Tech Projects"
      ],
      linkText: "Read More",
      linkHref: "#",
      color: { bg: "bg-blue-100", text: "text-blue-500" }
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-cyan-500">
          <path stroke="currentColor" stroke-linecap="square" stroke-linejoin="round" stroke-width="2" d="M7 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h1m4-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm7.441 1.559a1.907 1.907 0 0 1 0 2.698l-6.069 6.069L10 19l.674-3.372 6.07-6.07a1.907 1.907 0 0 1 2.697 0Z" />
        </svg>
      ),
      title: "Workforce Augmentation",
      description: "Augmented team members seamlessly integrate with your local or distributed team, participating in daily meetings and reporting directly to your managers. This approach enables businesses to scale rapidly and flexibly, meeting demands as they arise.",
      features: [
        "On - Demand Scaling",
        "Flexible Billing",
        "Cost - Effective Expertise",
        "Streamlined Hiring",
        "Rapid Replacements"
      ],
      linkText: "Read More",
      linkHref: "#",
      color: { bg: "bg-cyan-100", text: "text-cyan-500" }
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-teal-500">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 13h3.439a.991.991 0 0 1 .908.6 3.978 3.978 0 0 0 7.306 0 .99.99 0 0 1 .908-.6H20M4 13v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-6M4 13l2-9h12l2 9M9 7h6m-7 3h8" />
        </svg>
      ),
      title: "Fixed Project",
      description: "When project specifications, scope, deliverables, and acceptance criteria are clearly defined, we can provide a fixed quote. This approach is particularly well-suited for small to mid-scale projects with thoroughly documented requirements.",
      features: [
        "Ideal for MVP Development",
        "Milestone - Oriented Workflow",
        "Milestone - Based Payments",
        "Effective with Defined Scope"
      ],
      linkText: "Contact Us",
      linkHref: "#",
      color: { bg: "bg-teal-100", text: "text-teal-500" }
    }
  ];

  return (
    <section className="bg-white py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-extrabold leading-tight text-gray-900 dark:text-black sm:text-4xl text-center">Our Working Models</h2>
        <p className="text-light mt-4 text-gray-500 dark:text-gray-400 sm:text-xl text-center">Flexible engagement models: we can seamlessly integrate with your existing team or act as your dedicated team.</p>
        <br /><br />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {engagementModels.map((model, index) => (
            <EngagementModelCard key={index} {...model} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default EngagementModelsSection;