import React from 'react';
// import FoundersImg from '../../assets/images/people/founders.png'; // Adjust the path as needed

const FoundersCTA = () => {
  return (
    <section className="bg-white  light:bg-gray-900">
      <div className="mx-auto max-w-screen-xl px-24 py-8 sm:py-16 lg:px-60 2xl:max-w-screen-2xl">
        {/* <img
          className="mb-4 hidden w-full max-w-[280px] rounded-lg md:flex lg:mb-0 lg:flex"
          src={FoundersImg}
          alt="founders"
        /> */}
        <div className="max-w-screen-md lg:ml-16">
          <h2 className="mb-12 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-gray-800">
            We would love to work with you
          </h2>
          <p className="mb-12 mt-6 text-gray-600 dark:text-gray-400 md:text-lg">
            If you're looking for a strategic technology partner rather than just another outsourcing provider, connect with our co-founders, Satya or Praveen. Let's explore how we can work together to meet your software requirements and drive your business forward.
          </p>
          <div className="space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
            <a
              href="/contact-us"
              className="inline-block max-w-[120px] rounded-md bg-sky-600 px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
            >
              Talk to us <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FoundersCTA;